import logo from './logo.svg';
import './App.css';
import Header from "./Components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <>
          <Header/>

      </>
  );
}

export default App;
