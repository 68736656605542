import React, {Component} from 'react';

import {
    Navbar,
    Container
} from 'react-bootstrap';

import logo from "../logo.svg";

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Main from '../Pages/Main';

function Header() {
    return (
        <>
            <Navbar collapseOnSelect /*expand="md"*/ bg="dark" variant="dark" sticky="top">
                {/*fixed="top"*/}
                <Container>
                    <Navbar.Brand href="/">
                        <a href="/" className="svg">
                            <object type="image/svg+xml" data={logo} width="60" height="40"
                                    className="btn-group-vertical">
                                Logo
                            </object>
                        </a>
                        <div className="h2 d-inline brCpName textLeft btn-group-vertical">ТРУБОТОРГ</div>
                    </Navbar.Brand>
                    <a href="/Download/price_trubotorg32.pdf" className="btn btn-warning btnRight" download>Скачать
                        прайс</a>
                </Container>
            </Navbar>

            <Router>
                <Routes>
                    <Route exact path="/" element={<Main/>}/>
                </Routes>
            </Router>
        </>
    );
}

export default Header;