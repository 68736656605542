import React, {Component} from 'react';
import image from "../Assets/intro-image.jpg";
import {MDBContainer} from 'mdb-react-ui-kit';

class Main extends Component {
    render() {
        return (
            <>
                    <div className="imgHome">
                        <img src={image} alt="image" className="imgCover"/>
                    </div>

                <MDBContainer className="infoPanel mt-5">
                    <div className="row mb-2 g-3 w-100 mt-5">
                        <div className="col">
                            <h2 className="text-center h2 fw-bold textCenter">ООО "ДИЛАР"</h2>
                        </div>
                    </div>
                    <div className="row mb-2 g-3 w-100">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">
                            Юридический адрес организации:
                        </div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">241001, Россия, Брянская обл, г Брянск, пер Пилотов, д 18, кв 31
                        </div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">ИНН:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">3255053340</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">ОГРН:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">1063255031377</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">КПП:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">325701001</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">Расчетный счет:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">40702810610000305201</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">Банк:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">АО "ТИНЬКОФФ БАНК"</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">Юридический адрес банка:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">Москва, 123060, 1-й Волоколамский
                            проезд, д 10, стр
                            1
                        </div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">Корр.счет банка:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">30101810145250000974</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">ИНН банка:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">7710140679</div>
                    </div>
                    <div className="row mb-2 g-3 w-100 pt-lg-0 pt-4">
                        <div className="col-12 col-lg-6 text-lg-end text-center textLeft">БИК банка:</div>
                        <div className="col-12 col-lg-6 text-lg-start text-center">044525974</div>
                    </div>
                    <div className="row"><p></p></div>
                    <div className="row"><p></p></div>
                </MDBContainer>

                <div className="footer">
                    <MDBContainer className="mt-5">
                        <div className="container text-left">
                            <div className="row pt-5">
                                <div className="col-12 col-lg-4 mt-0 mt-lg-0 text-center">
                                    <h3><b>Адрес</b></h3>
                                    <hr className="accent-2 mb-4 mt-0 d-inline-block mx-auto"
                                        style={{width: "70px", color: "yellow"}}/>
                                    <p className="text-left fs-5">Склад г.Брянск, пос. Октябрьский, ул. Сталелитейная,
                                        20</p>
                                </div>
                                <div className="col-12 col-lg-4 mt-5 mt-lg-0 text-center">
                                    <h3><b>Время работы</b></h3>
                                    <hr className="accent-2 mb-4 mt-0 d-inline-block mx-auto"
                                        style={{width: "150px", color: "yellow"}}/>
                                    <div className="text-left fs-5">
                                        <p>Пн - Пт: 9:00 -17:00</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 mt-5 mt-lg-0 text-center">
                                    <h3><b>КОНТАКТЫ</b></h3>
                                    <hr className="accent-2 mb-4 mt-0 d-inline-block mx-auto"
                                        style={{width: "120px", color: "yellow"}}/>
                                    <div className="text-left lgN">
                                        <a href="tel:+74832377557" className="fs-5 textDecorationFooter"><i
                                            className="fa fa-phone mr-3"/> +7 (4832) 37-75-57</a>
                                        <p></p>
                                        <a href="tel:+74832371157" className="fs-5 textDecorationFooter"><i
                                            className="fa fa-phone mr-3"/> +7 (4832) 37-11-57</a>
                                        <p></p>
                                        <a href="mailto:dilar-bryansk@yandex.ru" className="fs-5 textDecorationFooter"><i
                                            className="fa fa-envelope mr-3"/> dilar-bryansk@yandex.ru
                                        </a>
                                        <p></p>
                                        <a href="mailto:trubotorg-bryansk@ya.ru" className="fs-5 textDecorationFooter"><i
                                            className="fa fa-envelope mr-3"/> trubotorg-bryansk@ya.ru
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-5 pt-lg-5">
                                <div className="col-12 text-center">
                                    ©{new Date().getFullYear()} Copyright: <a className="aFooter"
                                                                              href="https://www.trubotorg32.ru/">trubotorg32.ru</a>
                                </div>
                                <div className="col-12"><p></p></div>
                            </div>
                        </div>
                    </MDBContainer>
                </div>
            </>
        )
            ;
    }
}

export default Main;